<template>
  <section class="number-area ptb_30">
    <div class="mt-2 col-sm-6 col-md-12 col-lg-12 mb-3">
      <b-button style="margin:5px" class="bg-primary" v-b-modal.modal-illustration-kyagyo
        >Vocabulary Illustration</b-button >
      <modal :modalId="'modal-illustration-kyagyo'" :size="'xl'">
        <div slot="content">
          <b-img
            src="/assets/img/hiragana/you-on/HiraganaKYA-GYA.jpg"
            fluid
            alt="Fluid image"
          ></b-img>
          <b-button v-for="illustration in vocabkyagyo" :key="illustration.hiragana" v-bind:style="illustration.styleButton" class="btn syllable" @click="play(illustration.hiragana)"><p class="syllable text-white">{{ illustration.hiragana }}</p></b-button>
        </div>
      </modal>
      <b-button style="margin:5px" class="bg-primary" v-b-modal.modal-illustration-charyo
        >Vocabulary Illustration</b-button >
      <modal :modalId="'modal-illustration-charyo'" :size="'xl'">
        <div slot="content">
          <b-img
            src="/assets/img/hiragana/you-on/HiraganaCHA-RYA.jpg"
            fluid
            alt="Fluid image"
          ></b-img>
          <b-button v-for="illustration in vocabcharyo" :key="illustration.hiragana" v-bind:style="illustration.styleButton" class="btn syllable" @click="play(illustration.hiragana)"><p class="syllable text-white">{{ illustration.hiragana }}</p></b-button>
        </div>
      </modal>
      <b-button style="margin:5px" class="bg-primary" v-b-modal.modal-illustration-shajyo
        >Vocabulary Illustration</b-button >
      <modal :modalId="'modal-illustration-shajyo'" :size="'xl'">
        <div slot="content">
          <b-img
            src="/assets/img/hiragana/you-on/HiraganaSHA-JYA.jpg"
            fluid
            alt="Fluid image"
          ></b-img>
          <b-button v-for="illustration in vocabshajyo" :key="illustration.hiragana" v-bind:style="illustration.styleButton" class="btn syllable" @click="play(illustration.hiragana)"><p class="syllable text-white">{{ illustration.hiragana }}</p></b-button>
        </div>
      </modal>
    </div>
    <div class="col-sm-12 col-lg-12 text-center">
      <b-row class="mt-2">
        <b-col
          class="mt-2"
          cols="12" md="2"
          v-for="syllable in syllabels"
          :key="syllable.original"
        >
          <b-card>
            <b-card-text>
              <h3 style="font-size:65px" class="syllable">{{ syllable.original }}</h3>
              <h5 class="mt-2 romaji">{{ syllable.romaji }}</h5>
              <b-button
                class="bg-success mt-3 ml-2"
                @click="play(syllable.original)"
                ><em class="mdi mdi-account-tie-voice"></em
              ></b-button>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <modal :modalId="'modal-syllable-ao'">
        <div slot="content">
          <img
            class="img-fluid"
            alt="Responsive image"
            :src="imgSrc"
            width="100%"
          />
        </div>
      </modal>
    </div>
  </section>
</template>
<script>
import Modal from '../../../../Modal/Modal'
export default {
  components: { Modal },
  data () {
    return {
      syllabels: [
        {
          original: 'きゃ',
          romaji: 'kya'
        },
        {
          original: 'きゅ',
          romaji: 'kyu'
        },
        {
          original: 'きょ',
          romaji: 'kyo'
        },
        {
          original: 'しゃ',
          romaji: 'sha'
        },
        {
          original: 'しゅ',
          romaji: 'shu'
        },
        {
          original: 'しょ',
          romaji: 'sho'
        },
        {
          original: 'ちゃ',
          romaji: 'cha'
        },
        {
          original: 'ちゅ',
          romaji: 'chu'
        },
        {
          original: 'ちょ',
          romaji: 'cho'
        },
        {
          original: 'にゃ',
          romaji: 'nya'
        },
        {
          original: 'にゅ',
          romaji: 'nyu'
        },
        {
          original: 'にょ',
          romaji: 'nyo'
        },
        {
          original: 'ひゃ',
          romaji: 'hya'
        },
        {
          original: 'ひゅ',
          romaji: 'hyu'
        },
        {
          original: 'ひょ',
          romaji: 'hyo'
        },
        {
          original: 'みゃ',
          romaji: 'mya'
        },
        {
          original: 'みゅ',
          romaji: 'myu'
        },
        {
          original: 'みょ',
          romaji: 'myo'
        },
        {
          original: 'りゃ',
          romaji: 'rya'
        },
        {
          original: 'りゅ',
          romaji: 'ryu'
        },
        {
          original: 'りょ',
          romaji: 'ryo'
        },
        {
          original: 'ぎゃ',
          romaji: 'gya'
        },
        {
          original: 'ぎゅ',
          romaji: 'gyu'
        },
        {
          original: 'ぎょ',
          romaji: 'gyo'
        },
        {
          original: 'じゃ',
          romaji: 'ja'
        },
        {
          original: 'じゅ',
          romaji: 'ju'
        },
        {
          original: 'じょ',
          romaji: 'jo'
        },
        {
          original: 'びゃ',
          romaji: 'bya'
        },
        {
          original: 'びゅ',
          romaji: 'byu'
        },
        {
          original: 'びょ',
          romaji: 'byo'
        },
        {
          original: 'ぴゃ',
          romaji: 'pya'
        },
        {
          original: 'ぴゅ',
          romaji: 'pyu'
        },
        {
          original: 'ぴょ',
          romaji: 'pyo'
        }
      ],
      imgSrc: '',
      vocabkyagyo: [
        {
          hiragana: 'きゅう',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'べんきょうする',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'にんぎょう',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'ぎゃく',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        }
      ],
      vocabcharyo: [
        {
          hiragana: 'ひゃく',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'ちょうみりょう',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'ちゅうもん',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'りゅうがくせい',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'びょう',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'ぎゅうにゅう',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'おちゃ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        }
      ],
      vocabshajyo: [
        {
          hiragana: 'じゅぎょう',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'じょうず',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'しゃしん',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'じんじゃ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'しゅくだい',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'じしょ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        }
      ]
    }
  },
  methods: {
    play (val) {
      let soundSrc
      switch (val) {
      case 'きゃ':
        soundSrc = '/assets/sounds/hiraganayou-on/Kya-Kyo/Kya.mp3'
        break
      case 'きゅ':
        soundSrc = '/assets/sounds/hiraganayou-on/Kya-Kyo/Kyu.mp3'
        break
      case 'きょ':
        soundSrc = '/assets/sounds/hiraganayou-on/Kya-Kyo/Kyo.mp3'
        break
      case 'しゃ':
        soundSrc = '/assets/sounds/hiraganayou-on/Sha-Sho/Sha.mp3'
        break
      case 'しゅ':
        soundSrc = '/assets/sounds/hiraganayou-on/Sha-Sho/Shu.mp3'
        break
      case 'しょ':
        soundSrc = '/assets/sounds/hiraganayou-on/Sha-Sho/Sho.mp3'
        break
      case 'ちゃ':
        soundSrc = '/assets/sounds/hiraganayou-on/Cha-Cho/Cha.mp3'
        break
      case 'ちゅ':
        soundSrc = '/assets/sounds/hiraganayou-on/Cha-Cho/Chu.mp3'
        break
      case 'ちょ':
        soundSrc = '/assets/sounds/hiraganayou-on/Cha-Cho/Cho.mp3'
        break
      case 'にゃ':
        soundSrc = '/assets/sounds/hiraganayou-on/Nya-Nyo/Nya.mp3'
        break
      case 'にゅ':
        soundSrc = '/assets/sounds/hiraganayou-on/Nya-Nyo/Nyu.mp3'
        break
      case 'にょ':
        soundSrc = '/assets/sounds/hiraganayou-on/Nya-Nyo/Nyo.mp3'
        break
      case 'ひゃ':
        soundSrc = '/assets/sounds/hiraganayou-on/Hya-Hyo/Hya.mp3'
        break
      case 'ひゅ':
        soundSrc = '/assets/sounds/hiraganayou-on/Hya-Hyo/Hyu.mp3'
        break
      case 'ひょ':
        soundSrc = '/assets/sounds/hiraganayou-on/Hya-Hyo/Hyo.mp3'
        break
      case 'みゃ':
        soundSrc = '/assets/sounds/hiraganayou-on/Mya-Myo/Mya.mp3'
        break
      case 'みゅ':
        soundSrc = '/assets/sounds/hiraganayou-on/Mya-Myo/Myu.mp3'
        break
      case 'みょ':
        soundSrc = '/assets/sounds/hiraganayou-on/Mya-Myo/Myo.mp3'
        break
      case 'りゃ':
        soundSrc = '/assets/sounds/hiraganayou-on/Rya-Ryo/Rya.mp3'
        break
      case 'りゅ':
        soundSrc = '/assets/sounds/hiraganayou-on/Rya-Ryo/Ryu.mp3'
        break
      case 'りょ':
        soundSrc = '/assets/sounds/hiraganayou-on/Rya-Ryo/Ryo.mp3'
        break
      case 'ぎゃ':
        soundSrc = '/assets/sounds/hiraganayou-on/Gya-Gyo/Gya.mp3'
        break
      case 'ぎゅ':
        soundSrc = '/assets/sounds/hiraganayou-on/Gya-Gyo/Gyu.mp3'
        break
      case 'ぎょ':
        soundSrc = '/assets/sounds/hiraganayou-on/Gya-Gyo/Gyo.mp3'
        break
      case 'じゃ':
        soundSrc = '/assets/sounds/hiraganayou-on/Ja-Jo/Ja.mp3'
        break
      case 'じゅ':
        soundSrc = '/assets/sounds/hiraganayou-on/Ja-Jo/Ju.mp3'
        break
      case 'じょ':
        soundSrc = '/assets/sounds/hiraganayou-on/Ja-Jo/Jo.mp3'
        break
      case 'びゃ':
        soundSrc = '/assets/sounds/hiraganayou-on/Bya-Byo/Bya.mp3'
        break
      case 'びゅ':
        soundSrc = '/assets/sounds/hiraganayou-on/Bya-Byo/Byu.mp3'
        break
      case 'びょ':
        soundSrc = '/assets/sounds/hiraganayou-on/Bya-Byo/Byo.mp3'
        break
      case 'ぴゃ':
        soundSrc = '/assets/sounds/hiraganayou-on/Pya-Pyo/Pya.mp3'
        break
      case 'ぴゅ':
        soundSrc = '/assets/sounds/hiraganayou-on/Pya-Pyo/Pyu.mp3'
        break
      case 'ぴょ':
        soundSrc = '/assets/sounds/hiraganayou-on/Pya-Pyo/Pyo.mp3'
        break
      case 'きゅう':
        soundSrc = '/assets/sounds/illustration-vocab/hiraganakyaGyo/Hiragana_KYA_kyuu.mp3'
        break
      case 'べんきょうする':
        soundSrc = '/assets/sounds/illustration-vocab/hiraganakyaGyo/Hiragana_KYA_Benkyousuru.mp3'
        break
      case 'にんぎょう':
        soundSrc = '/assets/sounds/illustration-vocab/hiraganakyaGyo/Hiragana_KYA_ningyou.mp3'
        break
      case 'ぎゃく':
        soundSrc = '/assets/sounds/illustration-vocab/hiraganakyaGyo/Hiragana_KYA_Gyaku.mp3'
        break
      case 'ひゃく':
        soundSrc = '/assets/sounds/illustration-vocab/hiraganachaRyo/Hiragana_CHA_hyaku.mp3'
        break
      case 'ちょうみりょう':
        soundSrc = '/assets/sounds/illustration-vocab/hiraganachaRyo/Hiragana_CHA_choumiryou.mp3'
        break
      case 'ちゅうもん':
        soundSrc = '/assets/sounds/illustration-vocab/hiraganachaRyo/Hiragana_CHA_chuumon.mp3'
        break
      case 'りゅうがくせい':
        soundSrc = '/assets/sounds/illustration-vocab/hiraganachaRyo/Hiragana_CHA_ryuugakusei.mp3'
        break
      case 'びょう':
        soundSrc = '/assets/sounds/illustration-vocab/hiraganachaRyo/Hiragana_CHA_byou.mp3'
        break
      case 'ぎゅうにゅう':
        soundSrc = '/assets/sounds/illustration-vocab/hiraganachaRyo/Hiragana_CHA_gyuunyuu.mp3'
        break
      case 'おちゃ':
        soundSrc = '/assets/sounds/illustration-vocab/hiraganachaRyo/Hiragana_CHA_ocha.mp3'
        break
      case 'じゅぎょう':
        soundSrc = '/assets/sounds/illustration-vocab/hiraganashaJyo/Hiragana_SHA_jugyou.mp3'
        break
      case 'じょうず':
        soundSrc = '/assets/sounds/illustration-vocab/hiraganashaJyo/Hiragana_SHA_jouzu.mp3'
        break
      case 'しゃしん':
        soundSrc = '/assets/sounds/illustration-vocab/hiraganashaJyo/Hiragana_SHA_shashin.mp3'
        break
      case 'じんじゃ':
        soundSrc = '/assets/sounds/illustration-vocab/hiraganashaJyo/Hiragana_SHA_jinja.mp3'
        break
      case 'しゅくだい':
        soundSrc = '/assets/sounds/illustration-vocab/hiraganashaJyo/Hiragana_SHA_shukudai.mp3'
        break
      case 'じしょ':
        soundSrc = '/assets/sounds/illustration-vocab/hiraganashaJyo/Hiragana_SHA_jisho.mp3'
        break
      }
      const audio = new Audio(soundSrc)
      audio.play()
    }
  }
}
</script>
