<template>
  <section class="number-area ptb_30">
    <div class="mt-2 col-sm-6 col-md-12 col-lg-12 mb-3">
      <b-button class="bg-primary" v-b-modal.modal-illustration-gago
        >Vocabulary Illustration</b-button >
      <modal :modalId="'modal-illustration-gago'" :size="'xl'">
        <div slot="content">
          <b-img
            src="/assets/img/hiragana/daku-on/HiraganaGA.jpg"
            fluid
            alt="Fluid image"
          ></b-img>
          <b-button v-for="illustration in vocab" :key="illustration.hiragana" v-bind:style="illustration.styleButton" class="btn syllable" @click="play(illustration.hiragana)"><p class="syllable text-white">{{ illustration.hiragana }}</p></b-button>
        </div>
      </modal>
    </div>
    <div class="col-sm-12 col-lg-12 text-center">
      <b-row class="mt-2">
        <b-col
          class="mt-2"
          cols="12" md="2"
          v-for="syllable in syllabels"
          :key="syllable.original"
        >
          <b-card>
            <b-card-text>
              <h3 class="syllable">{{ syllable.original }}</h3>
              <h5 class="mt-2 romaji">{{ syllable.romaji }}</h5>
              <b-button
                class="bg-success mt-3 ml-2"
                @click="play(syllable.original)"
                ><em class="mdi mdi-account-tie-voice"></em
              ></b-button>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <modal :modalId="'modal-syllable-ao'">
        <div slot="content">
          <img
            class="img-fluid"
            alt="Responsive image"
            :src="imgSrc"
            width="100%"
          />
        </div>
      </modal>
    </div>
  </section>
</template>
<script>
import Modal from '../../../../Modal/Modal'
export default {
  components: { Modal },
  data () {
    return {
      syllabels: [
        {
          original: 'が',
          romaji: 'ga'
        },
        {
          original: 'ぎ',
          romaji: 'gi'
        },
        {
          original: 'ぐ',
          romaji: 'gu'
        },
        {
          original: 'げ',
          romaji: 'ge'
        },
        {
          original: 'ご',
          romaji: 'go'
        }
      ],
      imgSrc: '',
      vocab: [
        {
          hiragana: 'めがね',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'かぎ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'ご ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'げんき',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'いりぐち',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        }
      ]
    }
  },
  methods: {
    play (val) {
      let soundSrc
      switch (val) {
      case 'が':
        soundSrc = '/assets/sounds/hiraganadaku-on/gaGo/Ga.mp3'
        break
      case 'ぎ':
        soundSrc = '/assets/sounds/hiraganadaku-on/gaGo/Gi.mp3'
        break
      case 'ぐ':
        soundSrc = '/assets/sounds/hiraganadaku-on/gaGo/Gu.mp3'
        break
      case 'げ':
        soundSrc = '/assets/sounds/hiraganadaku-on/gaGo/Ge.mp3'
        break
      case 'ご':
        soundSrc = '/assets/sounds/hiraganadaku-on/gaGo/Go.mp3'
        break
      case 'めがね':
        soundSrc = '/assets/sounds/illustration-vocab/hiragaGo/Hiragana_GA_megane.mp3'
        break
      case 'かぎ':
        soundSrc = '/assets/sounds/illustration-vocab/hiragaGo/Hiragana_GA_kagi.mp3'
        break
      case 'ご ':
        soundSrc = '/assets/sounds/illustration-vocab/hiragaGo/Hiragana_GA_go.mp3'
        break
      case 'げんき':
        soundSrc = '/assets/sounds/illustration-vocab/hiragaGo/Hiragana_GA_genki.mp3'
        break
      case 'いりぐち':
        soundSrc = '/assets/sounds/illustration-vocab/hiragaGo/Hiragana_GA_iriguchi.mp3'
        break
      }
      const audio = new Audio(soundSrc)
      audio.play()
    }
  }
}
</script>
