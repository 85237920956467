<template>
  <section class="number-area ptb_30">
    <!--<div class="mt-2 col-sm-6 col-md-12 col-lg-12 mb-3">
      <b-button class="bg-primary" v-b-modal.modal-illustration-aSo
        >Vocabulary Illustration</b-button
      >
      <modal :modalId="'modal-illustration-aSo'" :size="'xl'">
        <div slot="content">
          <b-img
            src="/assets/img/katakana/sei-on/katakana-a-so.jpg"
            fluid
            alt="Fluid image"
          ></b-img>
          <b-button v-for="illustration in vocab" :key="illustration.katakana" v-bind:style="illustration.styleButton" class="btn syllable" @click="play(illustration.katakana)"><p class="syllable text-white">{{ illustration.katakana }}</p></b-button>
        </div>
      </modal>
    </div>-->
    <div class="col-sm-12 col-lg-12 text-center">
      <b-row class="mt-2">
        <b-img
            src="/assets/img/katakana/tokushu-on/tokushu-on1.jpg"
            fluid-grow
            alt="Fluid image"
          ></b-img>
      </b-row>
      <modal :modalId="'modal-syllable-kata-ao'">
        <div slot="content">
          <img
            class="img-fluid"
            alt="Responsive image"
            :src="imgSrc"
            width="100%"
          />
        </div>
      </modal>
    </div>
  </section>
</template>
<script>
import Modal from '../../../../Modal/Modal'
export default {
  components: { Modal },
  data () {
    return {
      syllabels: [
        {
          original: 'ファ',
          romaji: 'fa'
        },
        {
          original: 'フィ',
          romaji: 'fi'
        },
        {
          original: 'フェ',
          romaji: 'fe'
        },
        {
          original: 'フォ',
          romaji: 'fo'
        },
        {
          original: 'ウィ',
          romaji: 'wi'
        },
        {
          original: 'ウェ',
          romaji: 'we'
        },
        {
          original: 'ウォ',
          romaji: 'wo'
        },
        {
          original: 'イェ',
          romaji: 'ye'
        },
        {
          original: 'クヮ',
          romaji: 'kwa'
        },
        {
          original: 'クィ',
          romaji: 'kwi'
        },
        {
          original: 'クェ',
          romaji: 'kwe'
        },
        {
          original: 'クォ',
          romaji: 'kwo'
        },
        {
          original: 'ティ',
          romaji: 'ti'
        },
        {
          original: 'ひゅ',
          romaji: 'hyu'
        },
        {
          original: 'ひょ',
          romaji: 'hyo'
        },
        {
          original: 'みゃ',
          romaji: 'mya'
        },
        {
          original: 'みゅ',
          romaji: 'myu'
        },
        {
          original: 'みょ',
          romaji: 'myo'
        },
        {
          original: 'りゃ',
          romaji: 'rya'
        },
        {
          original: 'りゅ',
          romaji: 'ryu'
        },
        {
          original: 'りょ',
          romaji: 'ryo'
        },
        {
          original: 'ぎゃ',
          romaji: 'gya'
        },
        {
          original: 'ぎゅ',
          romaji: 'gyu'
        },
        {
          original: 'ぎょ',
          romaji: 'gyo'
        },
        {
          original: 'じゃ',
          romaji: 'ja'
        },
        {
          original: 'じゅ',
          romaji: 'ju'
        },
        {
          original: 'じょ',
          romaji: 'jo'
        },
        {
          original: 'びゃ',
          romaji: 'bya'
        },
        {
          original: 'びゅ',
          romaji: 'byu'
        },
        {
          original: 'びょ',
          romaji: 'byo'
        },
        {
          original: 'ぴゃ',
          romaji: 'pya'
        },
        {
          original: 'ぴゅ',
          romaji: 'pyu'
        },
        {
          original: 'ぴょ',
          romaji: 'pyo'
        }
      ],
      imgSrc: '',
      vocab: [
        {
          katakana: 'ソース',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'キウイ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'ケーキ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'めがねケース',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        }
      ]
    }
  },
  methods: {
    showModal (val) {
      switch (val) {
      case 'ア':
        this.imgSrc = '/assets/gif/kataA-O/a-min.gif'
        break
      case 'イ':
        this.imgSrc = '/assets/gif/kataA-O/i-min.gif'
        break
      case 'ウ':
        this.imgSrc = '/assets/gif/kataA-O/u-min.gif'
        break
      case 'エ':
        this.imgSrc = '/assets/gif/kataA-O/e-min.gif'
        break
      case 'オ':
        this.imgSrc = '/assets/gif/kataA-O/o-min.gif'
        break
      case 'カ':
        this.imgSrc = '/assets/gif/kataKa-Ko/ka-min.gif'
        break
      case 'キ':
        this.imgSrc = '/assets/gif/kataKa-Ko/ki-min.gif'
        break
      case 'ク':
        this.imgSrc = '/assets/gif/kataKa-Ko/ku-min.gif'
        break
      case 'ケ':
        this.imgSrc = '/assets/gif/kataKa-Ko/ke-min.gif'
        break
      case 'コ':
        this.imgSrc = '/assets/gif/kataKa-Ko/ko-min.gif'
        break
      }
      this.$bvModal.show('modal-syllable-kata-ao')
    },
    play (val) {
      switch (val) {
      case 'ア':
        this.soundSrc = '/assets/sounds/sei-on/1.mp3'
        break
      case 'イ':
        this.soundSrc = '/assets/sounds/sei-on/2.mp3'
        break
      case 'ウ':
        this.soundSrc = '/assets/sounds/sei-on/3.mp3'
        break
      case 'エ':
        this.soundSrc = '/assets/sounds/sei-on/4.mp3'
        break
      case 'オ':
        this.soundSrc = '/assets/sounds/sei-on/5.mp3'
        break
      case 'カ':
        this.soundSrc = '/assets/sounds/sei-on/6.mp3'
        break
      case 'キ':
        this.soundSrc = '/assets/sounds/sei-on/7.mp3'
        break
      case 'ク':
        this.soundSrc = '/assets/sounds/sei-on/8.mp3'
        break
      case 'ケ':
        this.soundSrc = '/assets/sounds/sei-on/9.mp3'
        break
      case 'コ':
        this.soundSrc = '/assets/sounds/sei-on/10.mp3'
        break
      case 'サ':
        this.soundSrc = '/assets/sounds/sei-on/11.mp3'
        break
      case 'シ':
        this.soundSrc = '/assets/sounds/sei-on/12.mp3'
        break
      case 'ス':
        this.soundSrc = '/assets/sounds/sei-on/13.mp3'
        break
      case 'セ':
        this.soundSrc = '/assets/sounds/sei-on/14.mp3'
        break
      case 'ソ':
        this.soundSrc = '/assets/sounds/sei-on/15.mp3'
        break
      case 'ソース':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_A-SO_soosu.mp3'
        break
      case 'キウイ':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_A-SO_kiui.mp3'
        break
      case 'ケーキ':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_A-SO_keeki.mp3'
        break
      case 'めがねケース':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_A-SO_meganekeesu.mp3'
        break
      }
      const audio = new Audio(this.soundSrc)
      audio.play()
    }
  }
}
</script>
