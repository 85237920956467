<template>
  <section class="number-area ptb_30">
    <div class="mt-2 col-sm-6 col-md-12 col-lg-12 mb-3">
      <b-button class="bg-primary" v-b-modal.modal-illustration-state
        >Sounds</b-button
      >
      <modal :modalId="'modal-illustration-state'" :size="'xl'">
        <div slot="content">
         <b-row class="mt-2" style="margin-left:0px !important;">
        <b-img
            src="/assets/img/malaysia/asset17_japan.png"
            fluid
            alt="Fluid image"
          ></b-img>
         </b-row>
          <b-row class="mt-2" style="margin-left:0px !important;">
            <b-button v-for="illustration in vocab" :key="illustration.katakana" v-bind:style="illustration.styleButton" class="btn syllable" @click="play(illustration.katakana)"><p class="syllable text-white">{{ illustration.katakana }}</p></b-button>
          </b-row>
        </div>
      </modal>
    </div>
    <div class="col-sm-12 col-lg-12 text-center">
      <b-row class="mt-2">
        <b-img
            src="/assets/img/malaysia/asset17_japan.png"
            fluid-grow
            alt="Fluid image"
          ></b-img>
      </b-row>
      <!--<modal :modalId="'modal-syllable-kata-ao'">
        <div slot="content">
          <img
            class="img-fluid"
            alt="Responsive image"
            :src="imgSrc"
            width="100%"
          />
        </div>
      </modal>-->
    </div>
  </section>
</template>
<script>
import Modal from '../../../Modal/Modal'
export default {
  components: { Modal },
  data () {
    return {
      syllabels: [
        {
          original: 'ファ',
          romaji: 'fa'
        },
        {
          original: 'フィ',
          romaji: 'fi'
        },
        {
          original: 'フェ',
          romaji: 'fe'
        },
        {
          original: 'フォ',
          romaji: 'fo'
        },
        {
          original: 'ウィ',
          romaji: 'wi'
        },
        {
          original: 'ウェ',
          romaji: 'we'
        },
        {
          original: 'ウォ',
          romaji: 'wo'
        },
        {
          original: 'イェ',
          romaji: 'ye'
        },
        {
          original: 'クヮ',
          romaji: 'kwa'
        },
        {
          original: 'クィ',
          romaji: 'kwi'
        },
        {
          original: 'クェ',
          romaji: 'kwe'
        },
        {
          original: 'クォ',
          romaji: 'kwo'
        },
        {
          original: 'ティ',
          romaji: 'ti'
        },
        {
          original: 'ひゅ',
          romaji: 'hyu'
        },
        {
          original: 'ひょ',
          romaji: 'hyo'
        },
        {
          original: 'みゃ',
          romaji: 'mya'
        },
        {
          original: 'みゅ',
          romaji: 'myu'
        },
        {
          original: 'みょ',
          romaji: 'myo'
        },
        {
          original: 'りゃ',
          romaji: 'rya'
        },
        {
          original: 'りゅ',
          romaji: 'ryu'
        },
        {
          original: 'りょ',
          romaji: 'ryo'
        },
        {
          original: 'ぎゃ',
          romaji: 'gya'
        },
        {
          original: 'ぎゅ',
          romaji: 'gyu'
        },
        {
          original: 'ぎょ',
          romaji: 'gyo'
        },
        {
          original: 'じゃ',
          romaji: 'ja'
        },
        {
          original: 'じゅ',
          romaji: 'ju'
        },
        {
          original: 'じょ',
          romaji: 'jo'
        },
        {
          original: 'びゃ',
          romaji: 'bya'
        },
        {
          original: 'びゅ',
          romaji: 'byu'
        },
        {
          original: 'びょ',
          romaji: 'byo'
        },
        {
          original: 'ぴゃ',
          romaji: 'pya'
        },
        {
          original: 'ぴゅ',
          romaji: 'pyu'
        },
        {
          original: 'ぴょ',
          romaji: 'pyo'
        }
      ],
      imgSrc: '',
      vocab: [
        {
          katakana: 'ペルリス',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'ケダ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'クランタン',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'ペナン',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'パハン',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'ペラ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'トレンガヌ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'サバ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'スランゴール',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'ネグリセンビラン',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'サラワク',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'マラッカ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'ジョホール',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        }
      ]
    }
  },
  methods: {
    showModal (val) {
      switch (val) {
      case 'ア':
        this.imgSrc = '/assets/gif/kataA-O/a-min.gif'
        break
      case 'イ':
        this.imgSrc = '/assets/gif/kataA-O/i-min.gif'
        break
      case 'ウ':
        this.imgSrc = '/assets/gif/kataA-O/u-min.gif'
        break
      case 'エ':
        this.imgSrc = '/assets/gif/kataA-O/e-min.gif'
        break
      case 'オ':
        this.imgSrc = '/assets/gif/kataA-O/o-min.gif'
        break
      case 'カ':
        this.imgSrc = '/assets/gif/kataKa-Ko/ka-min.gif'
        break
      case 'キ':
        this.imgSrc = '/assets/gif/kataKa-Ko/ki-min.gif'
        break
      case 'ク':
        this.imgSrc = '/assets/gif/kataKa-Ko/ku-min.gif'
        break
      case 'ケ':
        this.imgSrc = '/assets/gif/kataKa-Ko/ke-min.gif'
        break
      case 'コ':
        this.imgSrc = '/assets/gif/kataKa-Ko/ko-min.gif'
        break
      }
      this.$bvModal.show('modal-syllable-kata-ao')
    },
    play (val) {
      switch (val) {
      case 'ペルリス':
        this.soundSrc = '/assets/sounds/katakana-malaysia/perlis.mp3'
        break
      case 'ケダ':
        this.soundSrc = '/assets/sounds/katakana-malaysia/kedah.mp3'
        break
      case 'クランタン':
        this.soundSrc = '/assets/sounds/katakana-malaysia/kelantan.mp3'
        break
      case 'ペナン':
        this.soundSrc = '/assets/sounds/katakana-malaysia/penang.mp3'
        break
      case 'ペラ':
        this.soundSrc = '/assets/sounds/katakana-malaysia/perak.mp3'
        break
      case 'トレンガヌ':
        this.soundSrc = '/assets/sounds/katakana-malaysia/terengganu.mp3'
        break
      case 'パハン':
        this.soundSrc = '/assets/sounds/katakana-malaysia/pahang.mp3'
        break
      case 'サバ':
        this.soundSrc = '/assets/sounds/katakana-malaysia/sabah.mp3'
        break
      case 'スランゴール':
        this.soundSrc = '/assets/sounds/katakana-malaysia/selangor.mp3'
        break
      case 'ネグリセンビラン':
        this.soundSrc = '/assets/sounds/katakana-malaysia/negeri_sembilan.mp3'
        break
      case 'サラワク':
        this.soundSrc = '/assets/sounds/katakana-malaysia/sarawak.mp3'
        break
      case 'マラッカ':
        this.soundSrc = '/assets/sounds/katakana-malaysia/melaka.mp3'
        break
      case 'ジョホール':
        this.soundSrc = '/assets/sounds/katakana-malaysia/johor.mp3'
        break
      }
      const audio = new Audio(this.soundSrc)
      audio.play()
    }
  }
}
</script>
