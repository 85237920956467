<template>
  <section class="number-area ptb_30">
    <div class="mt-2 col-sm-6 col-md-12 col-lg-12 mb-3">
      <b-button class="bg-primary" v-b-modal.modal-illustration-zazo
        >Vocabulary Illustration</b-button >
      <modal :modalId="'modal-illustration-zazo'" :size="'xl'">
        <div slot="content">
          <b-img
            src="/assets/img/hiragana/daku-on/HiraganaZA.jpg"
            fluid
            alt="Fluid image"
          ></b-img>
          <b-button v-for="illustration in vocab" :key="illustration.hiragana" v-bind:style="illustration.styleButton" class="btn syllable" @click="play(illustration.hiragana)"><p class="syllable text-white">{{ illustration.hiragana }}</p></b-button>
        </div>
      </modal>
    </div>
    <div class="col-sm-12 col-lg-12 text-center">
      <b-row class="mt-2">
        <b-col
          class="mt-2"
          cols="12" md="2"
          v-for="syllable in syllabels"
          :key="syllable.original"
        >
          <b-card>
            <b-card-text>
              <h3 class="syllable">{{ syllable.original }}</h3>
              <h5 class="mt-2 romaji">{{ syllable.romaji }}</h5>
              <b-button
                class="bg-success mt-3 ml-2"
                @click="play(syllable.original)"
                ><em class="mdi mdi-account-tie-voice"></em
              ></b-button>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <modal :modalId="'modal-syllable-ao'">
        <div slot="content">
          <img
            class="img-fluid"
            alt="Responsive image"
            :src="imgSrc"
            width="100%"
          />
        </div>
      </modal>
    </div>
  </section>
</template>
<script>
import Modal from '../../../../Modal/Modal'
export default {
  components: { Modal },
  data () {
    return {
      syllabels: [
        {
          original: 'ざ ',
          romaji: 'za'
        },
        {
          original: 'じ',
          romaji: 'ji'
        },
        {
          original: 'ず',
          romaji: 'zu'
        },
        {
          original: 'ぜ',
          romaji: 'ze'
        },
        {
          original: 'ぞ',
          romaji: 'zo'
        }
      ],
      imgSrc: '',
      vocab: [
        {
          hiragana: 'かぜ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'かんじ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'ちず',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'はいざら',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'かぞく',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        }
      ]
    }
  },
  methods: {
    play (val) {
      let soundSrc
      switch (val) {
      case 'ざ ':
        soundSrc = '/assets/sounds/hiraganadaku-on/zaZo/Za.mp3'
        break
      case 'じ':
        soundSrc = '/assets/sounds/hiraganadaku-on/zaZo/Zi.mp3'
        break
      case 'ず':
        soundSrc = '/assets/sounds/hiraganadaku-on/zaZo/Zu.mp3'
        break
      case 'ぜ':
        soundSrc = '/assets/sounds/hiraganadaku-on/zaZo/Ze.mp3'
        break
      case 'ぞ':
        soundSrc = '/assets/sounds/hiraganadaku-on/zaZo/Zo.mp3'
        break
      case 'かぜ':
        soundSrc = '/assets/sounds/illustration-vocab/hirazaZo/Hiragana_ZA_kaze.mp3'
        break
      case 'かんじ':
        soundSrc = '/assets/sounds/illustration-vocab/hirazaZo/Hiragana_ZA_kanji.mp3'
        break
      case 'ちず':
        soundSrc = '/assets/sounds/illustration-vocab/hirazaZo/Hiragana_ZA_chizu.mp3'
        break
      case 'はいざら':
        soundSrc = '/assets/sounds/illustration-vocab/hirazaZo/Hiragana_ZA_haizara.mp3'
        break
      case 'かぞく':
        soundSrc = '/assets/sounds/illustration-vocab/hirazaZo/Hiragana_ZA_kazoku.mp3'
        break
      }
      const audio = new Audio(soundSrc)
      audio.play()
    }
  }
}
</script>
