<template>
  <section class="number-area ptb_30">
    <div class="mt-2 col-sm-6 col-md-12 col-lg-12 mb-3">
      <b-button class="bg-primary" v-b-modal.modal-illustration-babo
        >Vocabulary Illustration</b-button >
      <modal :modalId="'modal-illustration-babo'" :size="'xl'">
        <div slot="content">
          <b-img
            src="/assets/img/hiragana/daku-on/HiraganaBA.jpg"
            fluid
            alt="Fluid image"
          ></b-img>
          <b-button v-for="illustration in vocab" :key="illustration.hiragana" v-bind:style="illustration.styleButton" class="btn syllable" @click="play(illustration.hiragana)"><p class="syllable text-white">{{ illustration.hiragana }}</p></b-button>
        </div>
      </modal>
    </div>
    <div class="col-sm-12 col-lg-12 text-center">
      <b-row class="mt-2">
        <b-col
          class="mt-2"
          cols="12" md="2"
          v-for="syllable in syllabels"
          :key="syllable.original"
        >
          <b-card>
            <b-card-text>
              <h3 class="syllable">{{ syllable.original }}</h3>
              <h5 class="mt-2 romaji">{{ syllable.romaji }}</h5>
              <b-button
                class="bg-success mt-3 ml-2"
                @click="play(syllable.original)"
                ><em class="mdi mdi-account-tie-voice"></em
              ></b-button>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <modal :modalId="'modal-syllable-ao'">
        <div slot="content">
          <img
            class="img-fluid"
            alt="Responsive image"
            :src="imgSrc"
            width="100%"
          />
        </div>
      </modal>
    </div>
  </section>
</template>
<script>
import Modal from '../../../../Modal/Modal'
export default {
  components: { Modal },
  data () {
    return {
      syllabels: [
        {
          original: 'ば',
          romaji: 'ba'
        },
        {
          original: 'び',
          romaji: 'bi'
        },
        {
          original: 'ぶ',
          romaji: 'bu'
        },
        {
          original: 'べ',
          romaji: 'be'
        },
        {
          original: 'ぼ',
          romaji: 'bo'
        }
      ],
      imgSrc: '',
      vocab: [
        {
          hiragana: 'ぼうし',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'かびん',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'かばん',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'くちべに',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'あぶない',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        }
      ]
    }
  },
  methods: {
    play (val) {
      let soundSrc
      switch (val) {
      case 'ば':
        soundSrc = '/assets/sounds/hiraganadaku-on/baBo/Ba.mp3'
        break
      case 'び':
        soundSrc = '/assets/sounds/hiraganadaku-on/baBo/Bi.mp3'
        break
      case 'ぶ':
        soundSrc = '/assets/sounds/hiraganadaku-on/baBo/Bu.mp3'
        break
      case 'べ':
        soundSrc = '/assets/sounds/hiraganadaku-on/baBo/Be.mp3'
        break
      case 'ぼ':
        soundSrc = '/assets/sounds/hiraganadaku-on/baBo/Bo.mp3'
        break
      case 'ぼうし':
        soundSrc = '/assets/sounds/hiraganadaku-on/baBo/Hiragana_BA-PA_boushi.mp3'
        break
      case 'かびん':
        soundSrc = '/assets/sounds/hiraganadaku-on/baBo/Hiragana_BA-PA_yubi.mp3'
        break
      case 'かばん':
        soundSrc = '/assets/sounds/hiraganadaku-on/baBo/Hiragana_BA-PA_kaban.mp3'
        break
      case 'くちべに':
        soundSrc = '/assets/sounds/hiraganadaku-on/baBo/Hiragana_BA-PA_kuchibeni.mp3'
        break
      case 'あぶない':
        soundSrc = '/assets/sounds/hiraganadaku-on/baBo/Hiragana_BA-PA_abunai.mp3'
        break
      }
      const audio = new Audio(soundSrc)
      audio.play()
    }
  }
}
</script>
