<template>
  <section class="number-area ptb_30">
    <div class="mt-2 col-sm-6 col-md-12 col-lg-12 mb-3">
      <b-button class="bg-primary" v-b-modal.modal-illustration-saSyo
        >Vocabulary Illustration</b-button
      >
      <modal :modalId="'modal-illustration-saSyo'" :size="'xl'">
        <div slot="content">
          <b-img
            src="/assets/img/katakana/soku-on/KatakanaSS.jpg"
            fluid
            alt="Fluid image"
          ></b-img>
          <b-button v-for="illustration in vocab" :key="illustration.katakana" v-bind:style="illustration.styleButton" class="btn syllable" @click="play(illustration.katakana)"><p class="syllable text-white">{{ illustration.katakana }}</p></b-button>
        </div>
      </modal>
    </div>
    <div class="col-sm-12 col-lg-12 text-center">
      <b-row class="mt-2">
        <b-col
          class="mt-2"
          cols="12" md="6"
          v-for="syllable in syllabels"
          :key="syllable.original"
        >
          <b-card class="mb-2">
            <b-card-text>
              <h3 style="font-size:65px" class="syllable">{{ syllable.original }}</h3>
              <h5 class="mt-2 romaji">{{ syllable.romaji }}</h5>
              <!--<b-button
                class="bg-success mt-3 ml-2"
                @click="play(syllable.original)"
                ><em class="mdi mdi-account-tie-voice"></em
              ></b-button>-->
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <modal :modalId="'modal-syllable-kata-ao'">
        <div slot="content">
          <img
            class="img-fluid"
            alt="Responsive image"
            :src="imgSrc"
            width="100%"
          />
        </div>
      </modal>
    </div>
  </section>
</template>
<script>
import Modal from '../../../../Modal/Modal'
export default {
  components: { Modal },
  data () {
    return {
      syllabels: [
        {
          original: 'コップ',
          romaji: 'pp'
        },
        {
          original: 'シャッター',
          romaji: 'tt'
        },
        {
          original: 'メッセージ',
          romaji: 'ss'
        },
        {
          original: 'サッカー',
          romaji: 'kk'
        }
      ],
      imgSrc: '',
      vocab: [
        {
          katakana: 'スイッチ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'ジャケット',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'シャッター',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'ホッチキス',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'クッキー',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'コップ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'カッター',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'マッチ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'ラッパ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        }
      ]
    }
  },
  methods: {
    showModal (val) {
      switch (val) {
      case 'ア':
        this.imgSrc = '/assets/gif/kataA-O/a-min.gif'
        break
      case 'イ':
        this.imgSrc = '/assets/gif/kataA-O/i-min.gif'
        break
      case 'ウ':
        this.imgSrc = '/assets/gif/kataA-O/u-min.gif'
        break
      case 'エ':
        this.imgSrc = '/assets/gif/kataA-O/e-min.gif'
        break
      case 'オ':
        this.imgSrc = '/assets/gif/kataA-O/o-min.gif'
        break
      case 'カ':
        this.imgSrc = '/assets/gif/kataKa-Ko/ka-min.gif'
        break
      case 'キ':
        this.imgSrc = '/assets/gif/kataKa-Ko/ki-min.gif'
        break
      case 'ク':
        this.imgSrc = '/assets/gif/kataKa-Ko/ku-min.gif'
        break
      case 'ケ':
        this.imgSrc = '/assets/gif/kataKa-Ko/ke-min.gif'
        break
      case 'コ':
        this.imgSrc = '/assets/gif/kataKa-Ko/ko-min.gif'
        break
      }
      this.$bvModal.show('modal-syllable-kata-ao')
    },
    play (val) {
      switch (val) {
      case 'ア':
        this.soundSrc = '/assets/sounds/sei-on/1.mp3'
        break
      case 'イ':
        this.soundSrc = '/assets/sounds/sei-on/2.mp3'
        break
      case 'ウ':
        this.soundSrc = '/assets/sounds/sei-on/3.mp3'
        break
      case 'エ':
        this.soundSrc = '/assets/sounds/sei-on/4.mp3'
        break
      case 'オ':
        this.soundSrc = '/assets/sounds/sei-on/5.mp3'
        break
      case 'カ':
        this.soundSrc = '/assets/sounds/sei-on/6.mp3'
        break
      case 'キ':
        this.soundSrc = '/assets/sounds/sei-on/7.mp3'
        break
      case 'ク':
        this.soundSrc = '/assets/sounds/sei-on/8.mp3'
        break
      case 'ケ':
        this.soundSrc = '/assets/sounds/sei-on/9.mp3'
        break
      case 'コ':
        this.soundSrc = '/assets/sounds/sei-on/10.mp3'
        break
      case 'サ':
        this.soundSrc = '/assets/sounds/sei-on/11.mp3'
        break
      case 'シ':
        this.soundSrc = '/assets/sounds/sei-on/12.mp3'
        break
      case 'ス':
        this.soundSrc = '/assets/sounds/sei-on/13.mp3'
        break
      case 'セ':
        this.soundSrc = '/assets/sounds/sei-on/14.mp3'
        break
      case 'ソ':
        this.soundSrc = '/assets/sounds/sei-on/15.mp3'
        break
      case 'スイッチ':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_SS_suicchi.mp3'
        break
      case 'ジャケット':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_SS_jaketto.mp3'
        break
      case 'シャッター':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_SS_shattaa.mp3'
        break
      case 'ホッチキス':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_SS_hocchikisu.mp3'
        break
      case 'クッキー':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_SS_kukkii.mp3'
        break
      case 'コップ':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_SS_koppu.mp3'
        break
      case 'カッター':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_SS_kattaa.mp3'
        break
      case 'マッチ':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_SS_macchi.mp3'
        break
      case 'ラッパ':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_SS_rappa.mp3'
        break
      }
      const audio = new Audio(this.soundSrc)
      audio.play()
    }
  }
}
</script>
