<template>
  <section class="number-area ptb_30">
    <div class="mt-2 col-sm-6 col-md-12 col-lg-12 mb-3">
      <b-button class="bg-primary" v-b-modal.modal-illustration-gaDo
        >Vocabulary Illustration</b-button
      >
      <modal :modalId="'modal-illustration-gaDo'" :size="'xl'">
        <div slot="content">
          <b-img
            src="/assets/img/katakana/daku-on/KatakanaGA-DO.jpg"
            fluid
            alt="Fluid image"
          ></b-img>
          <b-button v-for="illustration in vocab" :key="illustration.katakana" v-bind:style="illustration.styleButton" class="btn syllable" @click="play(illustration.katakana)"><p class="syllable text-white">{{ illustration.katakana }}</p></b-button>
        </div>
      </modal>
    </div>
    <div class="col-sm-12 col-lg-12 text-center">
      <b-row class="mt-2">
        <b-col
          class="mt-2"
          cols="12" md="2"
          v-for="syllable in syllabels"
          :key="syllable.original"
        >
          <b-card class="mb-2">
            <b-card-text>
              <h3 style="font-size:65px" class="syllable">{{ syllable.original }}</h3>
              <h5 class="mt-2 romaji">{{ syllable.romaji }}</h5>
              <b-button
                class="bg-success mt-3 ml-2"
                @click="play(syllable.original)"
                ><em class="mdi mdi-account-tie-voice"></em
              ></b-button>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <modal :modalId="'modal-syllable-kata-ao'">
        <div slot="content">
          <img
            class="img-fluid"
            alt="Responsive image"
            :src="imgSrc"
            width="100%"
          />
        </div>
      </modal>
    </div>
  </section>
</template>
<script>
import Modal from '../../../../Modal/Modal'
export default {
  components: { Modal },
  data () {
    return {
      syllabels: [
        {
          original: 'ガ',
          romaji: 'ga'
        },
        {
          original: 'ギ',
          romaji: 'gi'
        },
        {
          original: 'グ',
          romaji: 'gu'
        },
        {
          original: 'ゲ',
          romaji: 'ge'
        },
        {
          original: 'ゴ',
          romaji: 'go'
        },
        {
          original: 'ザ',
          romaji: 'za'
        },
        {
          original: 'ジ',
          romaji: 'ji'
        },
        {
          original: 'ズ',
          romaji: 'zu'
        },
        {
          original: 'ゼ',
          romaji: 'ze'
        },
        {
          original: 'ゾ',
          romaji: 'zo'
        },
        {
          original: 'ダ',
          romaji: 'da'
        },
        {
          original: 'ヂ',
          romaji: 'ji'
        },
        {
          original: 'ヅ',
          romaji: 'zu'
        },
        {
          original: 'デ',
          romaji: 'de'
        },
        {
          original: 'ド',
          romaji: 'do'
        }
      ],
      imgSrc: '',
      vocab: [
        {
          katakana: 'ドーナツ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'ラジオ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'ゼロ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'マンゴー',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'レジ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'ギター',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'ガム',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'サンダル',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        }
      ]
    }
  },
  methods: {
    showModal (val) {
      switch (val) {
      case 'ア':
        this.imgSrc = '/assets/gif/kataA-O/a-min.gif'
        break
      case 'イ':
        this.imgSrc = '/assets/gif/kataA-O/i-min.gif'
        break
      case 'ウ':
        this.imgSrc = '/assets/gif/kataA-O/u-min.gif'
        break
      case 'エ':
        this.imgSrc = '/assets/gif/kataA-O/e-min.gif'
        break
      case 'オ':
        this.imgSrc = '/assets/gif/kataA-O/o-min.gif'
        break
      case 'カ':
        this.imgSrc = '/assets/gif/kataKa-Ko/ka-min.gif'
        break
      case 'キ':
        this.imgSrc = '/assets/gif/kataKa-Ko/ki-min.gif'
        break
      case 'ク':
        this.imgSrc = '/assets/gif/kataKa-Ko/ku-min.gif'
        break
      case 'ケ':
        this.imgSrc = '/assets/gif/kataKa-Ko/ke-min.gif'
        break
      case 'コ':
        this.imgSrc = '/assets/gif/kataKa-Ko/ko-min.gif'
        break
      }
      this.$bvModal.show('modal-syllable-kata-ao')
    },
    play (val) {
      switch (val) {
      case 'ガ':
        this.soundSrc = '/assets/sounds/hiraganadaku-on/gaGo/Ga.mp3'
        break
      case 'ギ':
        this.soundSrc = '/assets/sounds/hiraganadaku-on/gaGo/Gi.mp3'
        break
      case 'グ':
        this.soundSrc = '/assets/sounds/hiraganadaku-on/gaGo/Gu.mp3'
        break
      case 'ゲ':
        this.soundSrc = '/assets/sounds/hiraganadaku-on/gaGo/Ge.mp3'
        break
      case 'ゴ':
        this.soundSrc = '/assets/sounds/hiraganadaku-on/gaGo/Go.mp3'
        break
      case 'ザ':
        this.soundSrc = '/assets/sounds/hiraganadaku-on/zaZo/Za.mp3'
        break
      case 'ジ':
        this.soundSrc = '/assets/sounds/hiraganadaku-on/zaZo/Zi.mp3'
        break
      case 'ズ':
        this.soundSrc = '/assets/sounds/hiraganadaku-on/zaZo/Zu.mp3'
        break
      case 'ゼ':
        this.soundSrc = '/assets/sounds/hiraganadaku-on/zaZo/Ze.mp3'
        break
      case 'ゾ':
        this.soundSrc = '/assets/sounds/hiraganadaku-on/zaZo/Zo.mp3'
        break
      case 'ダ':
        this.soundSrc = '/assets/sounds/hiraganadaku-on/daDo/Da.mp3'
        break
      case 'ヂ':
        this.soundSrc = '/assets/sounds/hiraganadaku-on/daDo/Di.mp3'
        break
      case 'ヅ':
        this.soundSrc = '/assets/sounds/hiraganadaku-on/daDo/Du.mp3'
        break
      case 'デ':
        this.soundSrc = '/assets/sounds/hiraganadaku-on/daDo/De.mp3'
        break
      case 'ド':
        this.soundSrc = '/assets/sounds/hiraganadaku-on/daDo/Do.mp3'
        break
      case 'ドーナツ':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_GA_doonatsu.mp3'
        break
      case 'ラジオ':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_GA_rajio.mp3'
        break
      case 'ゼロ':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_GA_zero.mp3'
        break
      case 'マンゴー':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_GA_mangoo.mp3'
        break
      case 'レジ':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_GA_reji.mp3'
        break
      case 'ギター':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_GA_gitaa.mp3'
        break
      case 'ガム':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_GA_gamu.mp3'
        break
      case 'サンダル':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_GA_sandaru.mp3'
        break
      }
      const audio = new Audio(this.soundSrc)
      audio.play()
    }
  }
}
</script>
