<template>
  <section class="number-area ptb_30">
    <div class="mt-2 col-sm-6 col-md-12 col-lg-12 mb-3">
      <b-button class="bg-primary" v-b-modal.modal-illustration-baBo
        >Vocabulary Illustration</b-button
      >
      <modal :modalId="'modal-illustration-baBo'" :size="'xl'">
        <div slot="content">
          <b-img
            src="/assets/img/katakana/daku-on/KatakanaBA.jpg"
            fluid
            alt="Fluid image"
          ></b-img>
          <b-button v-for="illustration in vocab" :key="illustration.katakana" v-bind:style="illustration.styleButton" class="btn syllable" @click="play(illustration.katakana)"><p class="syllable text-white">{{ illustration.katakana }}</p></b-button>
        </div>
      </modal>
    </div>
    <div class="col-sm-12 col-lg-12 text-center">
      <b-row class="mt-2">
        <b-col
          class="mt-2"
          cols="12" md="2"
          v-for="syllable in syllabels"
          :key="syllable.original"
        >
          <b-card class="mb-2">
            <b-card-text>
              <h3 style="font-size:65px" class="syllable">{{ syllable.original }}</h3>
              <h5 class="mt-2 romaji">{{ syllable.romaji }}</h5>
              <b-button
                class="bg-success mt-3 ml-2"
                @click="play(syllable.original)"
                ><em class="mdi mdi-account-tie-voice"></em
              ></b-button>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <modal :modalId="'modal-syllable-kata-ao'">
        <div slot="content">
          <img
            class="img-fluid"
            alt="Responsive image"
            :src="imgSrc"
            width="100%"
          />
        </div>
      </modal>
    </div>
  </section>
</template>
<script>
import Modal from '../../../../Modal/Modal'
export default {
  components: { Modal },
  data () {
    return {
      syllabels: [
        {
          original: 'バ',
          romaji: 'ba'
        },
        {
          original: 'ビ',
          romaji: 'bi'
        },
        {
          original: 'ブ',
          romaji: 'bu'
        },
        {
          original: 'ベ',
          romaji: 'be'
        },
        {
          original: 'ボ',
          romaji: 'bo'
        }
      ],
      imgSrc: '',
      vocab: [
        {
          katakana: 'ボール',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'バナナ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'テレビ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'ベルト',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          katakana: 'ブーツ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        }
      ]
    }
  },
  methods: {
    showModal (val) {
      switch (val) {
      case 'ア':
        this.imgSrc = '/assets/gif/kataA-O/a-min.gif'
        break
      case 'イ':
        this.imgSrc = '/assets/gif/kataA-O/i-min.gif'
        break
      case 'ウ':
        this.imgSrc = '/assets/gif/kataA-O/u-min.gif'
        break
      case 'エ':
        this.imgSrc = '/assets/gif/kataA-O/e-min.gif'
        break
      case 'オ':
        this.imgSrc = '/assets/gif/kataA-O/o-min.gif'
        break
      case 'カ':
        this.imgSrc = '/assets/gif/kataKa-Ko/ka-min.gif'
        break
      case 'キ':
        this.imgSrc = '/assets/gif/kataKa-Ko/ki-min.gif'
        break
      case 'ク':
        this.imgSrc = '/assets/gif/kataKa-Ko/ku-min.gif'
        break
      case 'ケ':
        this.imgSrc = '/assets/gif/kataKa-Ko/ke-min.gif'
        break
      case 'コ':
        this.imgSrc = '/assets/gif/kataKa-Ko/ko-min.gif'
        break
      }
      this.$bvModal.show('modal-syllable-kata-ao')
    },
    play (val) {
      switch (val) {
      case 'バ':
        this.soundSrc = '/assets/sounds/hiraganadaku-on/baBo/Ba.mp3'
        break
      case 'ビ':
        this.soundSrc = '/assets/sounds/hiraganadaku-on/baBo/Bi.mp3'
        break
      case 'ブ':
        this.soundSrc = '/assets/sounds/hiraganadaku-on/baBo/Bu.mp3'
        break
      case 'ベ':
        this.soundSrc = '/assets/sounds/hiraganadaku-on/baBo/Be.mp3'
        break
      case 'ボ':
        this.soundSrc = '/assets/sounds/hiraganadaku-on/baBo/Bo.mp3'
        break
      case 'ボール':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_BA_booru.mp3'
        break
      case 'バナナ':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_BA_banana.mp3'
        break
      case 'テレビ':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_BA_terebi.mp3'
        break
      case 'ベルト':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_BA_beruto.mp3'
        break
      case 'ブーツ':
        this.soundSrc = '/assets/sounds/vocab-katakana/Katakana_BA_buutsu.mp3'
        break
      }
      const audio = new Audio(this.soundSrc)
      audio.play()
    }
  }
}
</script>
