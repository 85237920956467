<template>
  <section class="number-area ptb_30">
    <div class="mt-2 col-sm-6 col-md-12 col-lg-12 mb-3">
      <b-button class="bg-primary" v-b-modal.modal-illustration-tSu
        >Vocabulary Illustration</b-button >
      <modal :modalId="'modal-illustration-tSu'" :size="'xl'">
        <div slot="content">
          <b-img
            src="/assets/img/hiragana/soku-on/HiraganaTSU.jpg"
            fluid
            alt="Fluid image"
          ></b-img>
          <b-button v-for="illustration in vocab" :key="illustration.hiragana" v-bind:style="illustration.styleButton" class="btn syllable" @click="play(illustration.hiragana)"><p class="syllable text-white">{{ illustration.hiragana }}</p></b-button>
        </div>
      </modal>
    </div>
    <div class="col-sm-12 col-lg-12 text-center">
      <b-row class="mt-2">
        <b-col
          class="mt-2"
          cols="12" md="6"
          v-for="syllable in syllabels"
          :key="syllable.original"
        >
          <b-card>
            <b-card-text>
              <h3 class="syllable">{{ syllable.original }}</h3>
              <h5 class="mt-2 romaji">{{ syllable.romaji }}</h5>
              <!--<b-button
                class="bg-success mt-3 ml-2"
                @click="play(syllable.original)"
                ><em class="mdi mdi-account-tie-voice"></em
              ></b-button>-->
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <modal :modalId="'modal-syllable-ao'">
        <div slot="content">
          <img
            class="img-fluid"
            alt="Responsive image"
            :src="imgSrc"
            width="100%"
          />
        </div>
      </modal>
    </div>
  </section>
</template>
<script>
import Modal from '../../../../Modal/Modal'
export default {
  components: { Modal },
  data () {
    return {
      syllabels: [
        {
          original: 'きっぷ',
          romaji: 'pp'
        },
        {
          original: 'はっぴ',
          romaji: 'pp'
        },
        {
          original: ' きって',
          romaji: 'tt'
        },
        {
          original: 'あさって',
          romaji: 'tt'
        },
        {
          original: 'きっさてん',
          romaji: 'ss'
        },
        {
          original: 'じっさい',
          romaji: 'ss'
        },
        {
          original: 'せっけん',
          romaji: 'kk'
        },
        {
          original: 'にっき',
          romaji: 'kk'
        }
      ],
      imgSrc: '',
      vocab: [
        {
          hiragana: 'きっさてん',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'きっぷ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'きって',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'いっしょ',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        },
        {
          hiragana: 'さっし',
          styleButton: {
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#dc454a'
          }
        }
      ]
    }
  },
  methods: {
    play (val) {
      let soundSrc
      switch (val) {
      case 'ぱ':
        soundSrc = '/assets/sounds/hiraganahandaku-on/paPo/Pa.mp3'
        break
      case 'ぴ':
        soundSrc = '/assets/sounds/hiraganahandaku-on/paPo/Pi.mp3'
        break
      case ' ぷ':
        soundSrc = '/assets/sounds/hiraganahandaku-on/paPo/Pu.mp3'
        break
      case 'ぺ':
        soundSrc = '/assets/sounds/hiraganahandaku-on/paPo/Pe.mp3'
        break
      case 'ぽ':
        soundSrc = '/assets/sounds/hiraganahandaku-on/paPo/Po.mp3'
        break
      case 'きっさてん':
        soundSrc = '/assets/sounds/illustration-vocab/hiraganaTSU/Hiragana_smallTSU_kissaten.mp3'
        break
      case 'きっぷ':
        soundSrc = '/assets/sounds/illustration-vocab/hiraganaTSU/Hiragana_smallTSU_kippu.mp3'
        break
      case 'きって':
        soundSrc = '/assets/sounds/illustration-vocab/hiraganaTSU/Hiragana_smallTSU_kitte.mp3'
        break
      case 'いっしょ':
        soundSrc = '/assets/sounds/illustration-vocab/hiraganaTSU/Hiragana_smallTSU_issho.mp3'
        break
      case 'さっし':
        soundSrc = '/assets/sounds/illustration-vocab/hiraganaTSU/Hiragana_smallTSU_zasshi.mp3'
        break
      }
      const audio = new Audio(soundSrc)
      audio.play()
    }
  }
}
</script>
